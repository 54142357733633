import { CollapsibleWrapperProps } from '@web/atomic/atm.wrapper/wrapper.component';
import { ThemeProps } from '@web/atomic/obj.theme';
import styled from 'styled-components';
import { hexToRGBA } from '@global/utils/ui/color-fn';
import { Alpha, GrayColor, Spacing } from '../legacy/obj.constants';

export const MIN_HEIGHT = 100;
const padding = Spacing.Large;
export const SeeMoreBoxStyled = styled.div<ThemeProps>`
  position: relative;
  padding-bottom: ${padding};
  background: ${({ theme }) => theme.color.background};
`;

export const SeeMoreBoxShadowStyled = styled.div<{ expanded: boolean }>`
  opacity: ${(props) => (props.expanded ? 0 : 1)};
  transition: all 0.3s ease-in-out;
  position: absolute;
  bottom: ${padding};
  left: 0;
  width: 100%;
  height: ${MIN_HEIGHT}px;
  background-image: linear-gradient(
    ${({ theme }) => hexToRGBA(theme.name === 'dark' ? theme.color.background : GrayColor.White, Alpha.L4)},
    ${({ theme }) => hexToRGBA(theme.name === 'dark' ? theme.color.background : GrayColor.White, 1)}
  );
  z-index: 1;
`;

export const CollapsibleWrapperStyled = styled.div<CollapsibleWrapperProps>`
  max-height: ${(props) => (props.expanded ? `${props.contentHeight}px` : `${props.minHeight}px`)};
  padding-bottom: ${Spacing.Medium};
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
`;

export const SeeMoreBoxButtonWrapperStyled = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
`;

export const SeeMoreBoxContentStyled = styled.div`
  border: 1px solid transparent;
`;
