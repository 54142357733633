import { GatsbyButton } from '@components/atm.button/button.component';
import { toBoolean } from '@global/utils/boolean/to-boolean';
import { SleepDiaryTag } from '@global/utils/domain/entities';
import { mapNumberToSleepDiaryTagList } from '@global/utils/domain/tags-transformation';
import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import { useGetUserName } from '@lp-root/src/components/obj.custom-hooks/user-info.hook';
import WebviewLayout from '@lp-root/src/components/org.layout/webview-layout.component';
import { MarkdownRemark, Query } from '@lp-root/src/data/graphql/local-graphql-types';
import { PageProps } from '@lp-src/utils/local-types';
import { Body, Col, Grid, H1, H2, H3, Row } from '@web/atomic';
import { StickButtonWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import { ForcedFade } from '@web/atomic/legacy/obj.animation/animation.component.style';
import { SeeMoreBox } from '@web/atomic/mol.see-more-box';
import { useCloseMessengerModalCallback } from '@web/atomic/obj.custom-hooks/close-messenger-modal.hook';
import { hasWindow } from '@web/utils/platform';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import QueryString from 'querystringify';
import React, { useEffect, useState } from 'react';

export interface TipsPageProps {
  compressedTags: number;
  dayTips: MarkdownRemark[];
  nightTips: MarkdownRemark[];
}

interface NumberTipsPageQueryParams {
  tags?: number;
}
type StringBool = 'true' | 'false';
interface BoolTipsPageQueryParams {
  alcohol?: StringBool;
  bathroom?: StringBool;
  caffeine?: StringBool;
  exercise?: StringBool;
  light?: StringBool;
  meal?: StringBool;
  medicine?: StringBool;
  nicotine?: StringBool;
  nap?: StringBool;
  noise?: StringBool;
  pain?: StringBool;
  partner?: StringBool;
  temperature?: StringBool;
}

type TipsPageQueryParams = NumberTipsPageQueryParams & BoolTipsPageQueryParams;

const TipsPage: React.FunctionComponent<PageProps<TipsPageProps, Query>> = (props) => {
  const [user] = useGetUserName();
  const userName = user && user.first_name;

  const search = hasWindow() ? window.location.search : '';
  const queryParam: TipsPageQueryParams = QueryString.parse(search);

  const tags = getTags(queryParam);

  const dayTips: MarkdownRemark[] = props.pageContext.dayTips;
  const nightTips: MarkdownRemark[] = props.pageContext.nightTips;
  const [filteredDayTips, setFilteredDayTips] = useState([]);
  const [filteredNightTips, setFilteredNightTips] = useState([]);
  useEffect(() => {
    setFilteredDayTips(filterTips(tags, dayTips));
    setFilteredNightTips(filterTips(tags, nightTips));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { close: handleClose, loading } = useCloseMessengerModalCallback();

  return (
    <WebviewLayout>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Dicas de sono',
          description: 'Confira as dicas de sono da Vigilantes do Sono para você evitar deslizes antes de dormir',
        }}
      />

      <Grid>
        <Row mt mb>
          <Col xs={12} sm={12} md={7} lg={7}>
            <H1> Dicas para {userName ? <strong> {userName} </strong> : 'você'} </H1>
            <Body> Abaixo você pode conferir algumas dicas que selecionamos para você melhorar ainda mais o seu sono.</Body>
          </Col>
        </Row>
        {filteredDayTips && filteredDayTips.length > 0 && (
          <Row mt mb>
            <Col xs={12} sm={12} md={7} lg={7}>
              <>
                <H2> Dicas para o dia</H2>
                {filteredDayTips.map((tip) => (
                  <React.Fragment key={tip.frontmatter.title}>
                    <H3> Sobre &quot;{tip.frontmatter.title.toLowerCase()}&quot; </H3>
                    <SeeMoreBox>
                      <div dangerouslySetInnerHTML={{ __html: tip.html }} />
                    </SeeMoreBox>
                  </React.Fragment>
                ))}
              </>
            </Col>
          </Row>
        )}

        {filteredNightTips && filteredNightTips.length > 0 && (
          <Row mt mb>
            <Col xs={12} sm={12} md={7} lg={7}>
              <>
                <H2> Dicas para o sono</H2>
                {filteredNightTips.map((tip) => (
                  <React.Fragment key={tip.frontmatter.title}>
                    <H3> Sobre &quot;{tip.frontmatter.title.toLowerCase()}&quot; </H3>
                    <SeeMoreBox>
                      <div dangerouslySetInnerHTML={{ __html: tip.html }} />
                    </SeeMoreBox>
                  </React.Fragment>
                ))}
              </>
            </Col>
          </Row>
        )}
      </Grid>
      {userName && (
        <StickButtonWrapper>
          <ForcedFade show={true}>
            <GatsbyButton onClick={handleClose} loading={loading} expanded>
              Sair
            </GatsbyButton>
          </ForcedFade>
        </StickButtonWrapper>
      )}
    </WebviewLayout>
  );
};

const getTags = (params: TipsPageQueryParams) => {
  if (params.tags > 0) {
    return mapNumberToSleepDiaryTagList(params.tags);
  }

  if (Object.keys(params).length > 0) {
    const tags = [];
    if (toBoolean(params.alcohol)) tags.push(SleepDiaryTag.Alcohol);
    if (toBoolean(params.bathroom)) tags.push(SleepDiaryTag.Bathroom);
    if (toBoolean(params.caffeine)) tags.push(SleepDiaryTag.Caffeine);
    if (toBoolean(params.exercise)) tags.push(SleepDiaryTag.Exercise);
    if (toBoolean(params.light)) tags.push(SleepDiaryTag.Light);
    if (toBoolean(params.meal)) tags.push(SleepDiaryTag.Meal);
    if (toBoolean(params.medicine)) tags.push(SleepDiaryTag.Medicine);
    if (toBoolean(params.nicotine)) tags.push(SleepDiaryTag.Nicotine);
    if (toBoolean(params.nap)) tags.push(SleepDiaryTag.Nap);
    if (toBoolean(params.noise)) tags.push(SleepDiaryTag.Noise);
    if (toBoolean(params.pain)) tags.push(SleepDiaryTag.Pain);
    if (toBoolean(params.partner)) tags.push(SleepDiaryTag.Partner);
    if (toBoolean(params.temperature)) tags.push(SleepDiaryTag.Temperature);

    if (tags.length > 0) return tags;
  }

  const tagsQty = Object.keys(SleepDiaryTag).length;
  const tagsCombination = Math.pow(2, tagsQty) - 1;
  return mapNumberToSleepDiaryTagList(tagsCombination);
};

const filterTips = (tags: SleepDiaryTag[], tips: MarkdownRemark[]) => {
  return tags ? tips.filter((tip) => tags.includes(tip.frontmatter.tag as SleepDiaryTag)) : tips;
};

export default TipsPage;

export const query = graphql`
  query TipsPage {
    site {
      ...SiteUrl
    }
  }
`;
