/* eslint-disable no-bitwise */
import { SleepDiaryTag } from './entities';

/**
 * https://stackoverflow.com/a/28298587/3670829
 * @param value
 */
function getBits(value) {
  let b = 1;
  const res = [];
  while (b <= value) {
    if (b & value) res.push(b);
    b <<= 1;
  }
  return res;
}

/**
 * This method can "descompress" a number into a list of tags
 * using "a power of 2 decomposition algorithm"
 */
export const mapNumberToSleepDiaryTagList = (input: number): SleepDiaryTag[] => {
  const sleepDiaryTagDic = Object.values(SleepDiaryTag).reduce((acc, curr, index) => {
    const powOf2 = 1 << index;
    acc[powOf2] = curr;
    return acc;
  }, {});

  const bits = getBits(input);

  return bits.map((item) => sleepDiaryTagDic[item]);
};

/**
 * This method can "compress" a number into a list of tags
 * using "a power of 2 decomposition algorithm"
 */
export const mapSleepDiaryTagListToNumber = (input: SleepDiaryTag[]): number => {
  const sleepDiaryTagDic = Object.values(SleepDiaryTag).reduce((acc, curr, index) => {
    const powOf2 = 1 << index;
    acc[curr] = powOf2;
    return acc;
  }, {});

  return input.reduce((acc, curr) => acc + sleepDiaryTagDic[curr], 0);
};
