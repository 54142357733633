import { useClientRect } from '@web/atomic/obj.custom-hooks/client-rect.hook';
import React, { useEffect, useState } from 'react';
import { Button } from '../atm.button/button.component';
import { FaIcon } from '../legacy/atm.fa-icon';
import {
  CollapsibleWrapperStyled,
  MIN_HEIGHT,
  SeeMoreBoxButtonWrapperStyled,
  SeeMoreBoxContentStyled,
  SeeMoreBoxShadowStyled,
  SeeMoreBoxStyled,
} from './see-more-box.component.style';
import { Spacing } from '../legacy/obj.constants';

export interface SeeMoreBoxProps {
  defaultExpanded?: boolean;

  /**
   * If disabled, the "see more" will not be shown and the content will be expanded
   */
  disabled?: boolean;
}

export interface SeeMoreBoxState {
  expanded: boolean;
  contentHeight: number;
}

export const SeeMoreBox: React.FunctionComponent<SeeMoreBoxProps> = (props) => {
  const [rect, ref] = useClientRect();
  const [expanded, setExpanded] = useState(props.defaultExpanded);
  const [contentHeight, setContentHeight] = useState(0);
  const minHeight = MIN_HEIGHT;
  const shouldUseSeeMoreFunctionality = minHeight < contentHeight;

  useEffect(() => {
    if (rect) {
      const additionalBottomMargin = parseInt(Spacing.Large);
      setContentHeight(rect.height + additionalBottomMargin);
    }
  }, [rect]);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  if (props.disabled) {
    return <>{props.children}</>;
  }

  const content = <SeeMoreBoxContentStyled ref={ref}>{props.children}</SeeMoreBoxContentStyled>;

  return !shouldUseSeeMoreFunctionality ? (
    content
  ) : (
    <SeeMoreBoxStyled>
      <CollapsibleWrapperStyled expanded={expanded} contentHeight={contentHeight} minHeight={minHeight}>
        {content}
      </CollapsibleWrapperStyled>

      {shouldUseSeeMoreFunctionality && (
        <SeeMoreBoxButtonWrapperStyled>
          <Button kind="link-thin" onClick={handleToggle}>
            {expanded ? <FaIcon.ChevronUp /> : <FaIcon.ChevronDown />} {expanded ? 'Ver menos' : 'Ver mais'}
          </Button>
        </SeeMoreBoxButtonWrapperStyled>
      )}
      <SeeMoreBoxShadowStyled expanded={expanded} />
    </SeeMoreBoxStyled>
  );
};

SeeMoreBox.defaultProps = {};
